import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./Header";
import MainFeaturedPost from "./MainFeaturedPost";
import FeaturedPost from "./FeaturedPost";
import Main from "./Main";
//import Sidebar from './Sidebar';
import Footer from "./Footer";
import post1 from "./blog-post.1.md";
import post2 from "./blog-post.2.md";
import post3 from "./blog-post.3.md";

const sections = [
  { title: "Technology", url: "#" },
  { title: "Design", url: "#" },
  { title: "Culture", url: "#" },
  { title: "Business", url: "#" },
  { title: "Politics", url: "#" },
  { title: "Opinion", url: "#" },
  { title: "Science", url: "#" },
  { title: "Health", url: "#" },
  { title: "Style", url: "#" },
  { title: "Travel", url: "#" },
];

const mainFeaturedPosts = [
  {
    title: "",
    description: "",
    image: "/images/4201c19d8949d15cc8c6.jpg",
    imageText: "",
    linkText: "",
  },
  {
    title: "",
    description: "",
    image: "/images/4805a9f4907ea4544135.jpg",
    imageText: "",
    linkText: "",
  },
  {
    title: "",
    description: "",
    image: "/images/323168d630012a54cb38.jpg",
    imageText: "",
    linkText: "",
  },
  {
    title: "",
    description: "",
    image: "/images/403128899e82118a78de.jpg",
    imageText: "",
    linkText: "",
  },
];

const featuredPosts = [
  {
    title: "Featured post",
    date: "Nov 12",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random?wallpapers",
    imageLabel: "Image Text",
  },
  {
    title: "Post title",
    date: "Nov 11",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random?wallpapers",
    imageLabel: "Image Text",
  },
];

const posts = [post1, post2, post3];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Blog() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        {/* <Header title="广州群科国际贸易有限公司" sections={sections} /> */}
        <Header
          title="Charm-Tech (Guangzhou) International Co., Ltd."
          sections={sections}
        />
        <main>
          <MainFeaturedPost posts={mainFeaturedPosts} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="From the firehose" posts={posts} />
          </Grid>
        </main>
      </Container>
      {/* <Footer
        title="电话：020-81605975 传真：020-81623677"
        description="地址：广州市荔湾区花地大道中83号金昊大厦12楼 邮编：510375"
      /> */}
      <Footer
        title="Tel: 020-81605975 Fax: 020-81623677"
        description="Addr: Rm 1204, No 83 Huadi zhong Rd, Liwan District, Guangzhou, Guangdong Province, China PC: 510375"
      />
    </ThemeProvider>
  );
}
